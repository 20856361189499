import { RemixApp } from "@remix-ui/app";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as packageJson from "../../../../../package.json";
import { fileSystem, fileSystems } from "../files/fileSystem";
import { indexedDBFileSystem } from "../files/filesystems/indexedDB";
import { localStorageFS } from "../files/filesystems/localStorage";
import {
  fileSystemUtility,
  migrationTestData,
} from "../files/filesystems/fileSystemUtility";
import "./styles/preload.css";
import isElectron from "is-electron";
const _paq = (window._paq = window._paq || []);

export const Preload = (props: any) => {
  const [tip, setTip] = useState<string>("");
  const [supported, setSupported] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [showDownloader, setShowDownloader] = useState<boolean>(false);
  const remixFileSystems = useRef<fileSystems>(new fileSystems());
  const remixIndexedDB = useRef<fileSystem>(new indexedDBFileSystem());
  const localStorageFileSystem = useRef<fileSystem>(new localStorageFS());
  // url parameters to e2e test the fallbacks and error warnings
  const testmigrationFallback = useRef<boolean>(
    window.location.hash.includes("e2e_testmigration_fallback=true") &&
      window.location.host === "127.0.0.1:8080" &&
      window.location.protocol === "http:"
  );
  const testmigrationResult = useRef<boolean>(
    window.location.hash.includes("e2e_testmigration=true") &&
      window.location.host === "127.0.0.1:8080" &&
      window.location.protocol === "http:"
  );
  const testBlockStorage = useRef<boolean>(
    window.location.hash.includes("e2e_testblock_storage=true") &&
      window.location.host === "127.0.0.1:8080" &&
      window.location.protocol === "http:"
  );

  function loadAppComponent() {
    import("../../app")
      .then((AppComponent) => {
        const appComponent = new AppComponent.default();
        appComponent.run().then(() => {
          props.root.render(<RemixApp app={appComponent} />);
        });
      })
      .catch((err) => {
        _paq.push(["trackEvent", "Preload", "error", err && err.message]);
        console.error("Error loading XDCRemix:", err);
        setError(true);
      });
  }

  const downloadBackup = async () => {
    setShowDownloader(false);
    const fsUtility = new fileSystemUtility();
    await fsUtility.downloadBackup(
      remixFileSystems.current.fileSystems["localstorage"]
    );
    await migrateAndLoad();
  };

  const migrateAndLoad = async () => {
    setShowDownloader(false);
    const fsUtility = new fileSystemUtility();
    const migrationResult = await fsUtility.migrate(
      localStorageFileSystem.current,
      remixIndexedDB.current
    );
    _paq.push([
      "trackEvent",
      "Migrate",
      "result",
      migrationResult ? "success" : "fail",
    ]);
    await setFileSystems();
  };

  const setFileSystems = async () => {
    const fsLoaded = await remixFileSystems.current.setFileSystem([
      testmigrationFallback.current || testBlockStorage.current
        ? null
        : remixIndexedDB.current,
      testBlockStorage.current ? null : localStorageFileSystem.current,
    ]);
    if (fsLoaded) {
      console.log(fsLoaded.name + " activated");
      _paq.push(["trackEvent", "Storage", "activate", fsLoaded.name]);
      loadAppComponent();
    } else {
      _paq.push(["trackEvent", "Storage", "error", "no supported storage"]);
      setSupported(false);
    }
  };

  const testmigration = async () => {
    if (testmigrationResult.current) {
      const fsUtility = new fileSystemUtility();
      fsUtility.populateWorkspace(
        migrationTestData,
        remixFileSystems.current.fileSystems["localstorage"].fs
      );
    }
  };

  useEffect(() => {
    if (isElectron()) {
      loadAppComponent();
      return;
    }
    async function loadStorage() {
      (await remixFileSystems.current.addFileSystem(remixIndexedDB.current)) ||
        _paq.push([
          "trackEvent",
          "Storage",
          "error",
          "indexedDB not supported",
        ]);
      (await remixFileSystems.current.addFileSystem(
        localStorageFileSystem.current
      )) ||
        _paq.push([
          "trackEvent",
          "Storage",
          "error",
          "localstorage not supported",
        ]);
      await testmigration();
      remixIndexedDB.current.loaded &&
        (await remixIndexedDB.current.checkWorkspaces());
      localStorageFileSystem.current.loaded &&
        (await localStorageFileSystem.current.checkWorkspaces());
      remixIndexedDB.current.loaded &&
        (remixIndexedDB.current.hasWorkSpaces ||
        !localStorageFileSystem.current.hasWorkSpaces
          ? await setFileSystems()
          : setShowDownloader(true));
      !remixIndexedDB.current.loaded && (await setFileSystems());
    }
    loadStorage();

    const abortController = new AbortController();
    const signal = abortController.signal;
    async function showRemixTips() {
      const response = await axios.get(
        "https://raw.githubusercontent.com/remix-project-org/remix-dynamics/main/ide/tips.json",
        { signal }
      );
      if (signal.aborted) return;
      const tips = response.data;
      const index = Math.floor(Math.random() * (tips.length - 1));
      setTip(tips[index]);
    }
    try {
      showRemixTips();
    } catch (e) {
      console.log(e);
    }
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <div className="preload-container">
        <div className="preload-logo pb-4">
          {logo}
          <div className="info-secondary splash">
            REMIX IDE
            <br />
            <span className="version"> v{packageJson.version}</span>
          </div>
        </div>
        {!supported ? (
          <div className="preload-info-container alert alert-warning">
            Your browser does not support any of the filesystems required by
            XDCRemix. Either change the settings in your browser or use a
            supported browser.
          </div>
        ) : null}
        {error ? (
          <div className="preload-info-container alert alert-danger text-left">
            An unknown error has occurred while loading the application.
            <br></br>
            Doing a hard refresh might fix this issue:<br></br>
            <div className="pt-2">
              Windows:<br></br>- Chrome: CTRL + F5 or CTRL + Reload Button
              <br></br>- Firefox: CTRL + SHIFT + R or CTRL + F5<br></br>
            </div>
            <div className="pt-2">
              MacOS:<br></br>- Chrome & FireFox: CMD + SHIFT + R or SHIFT +
              Reload Button<br></br>
            </div>
            <div className="pt-2">
              Linux:<br></br>- Chrome & FireFox: CTRL + SHIFT + R<br></br>
            </div>
          </div>
        ) : null}
        {showDownloader ? (
          <div className="preload-info-container alert alert-info">
            This app will be updated now. Please download a backup of your files
            now to make sure you don't lose your work.
            <br></br>
            You don't need to do anything else, your files will be available
            when the app loads.
            <div
              onClick={async () => {
                await downloadBackup();
              }}
              data-id="downloadbackup-btn"
              className="btn btn-primary mt-1"
            >
              download backup
            </div>
            <div
              onClick={async () => {
                await migrateAndLoad();
              }}
              data-id="skipbackup-btn"
              className="btn btn-primary mt-1"
            >
              skip backup
            </div>
          </div>
        ) : null}
        {supported && !error && !showDownloader ? (
          <div>
            <div className="text-center">
              <i className="fas fa-spinner fa-spin fa-2x"></i>
            </div>
            {tip && (
              <div className="remix_tips text-center mt-3">
                <div>
                  <b>DID YOU KNOW</b>
                </div>
                <span>{tip}</span>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

const logo = (
  <svg
    id="Ebene_2"
    data-name="Ebene 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 105 100"
  >
    <path d="M91.84,35a.09.09,0,0,1-.1-.07,41,41,0,0,0-79.48,0,.09.09,0,0,1-.1.07C9.45,35,1,35.35,1,42.53c0,8.56,1,16,6,20.32,2.16,1.85,5.81,2.3,9.27,2.22a44.4,44.4,0,0,0,6.45-.68.09.09,0,0,0,.06-.15A34.81,34.81,0,0,1,17,45c0-.1,0-.21,0-.31a35,35,0,0,1,70,0c0,.1,0,.21,0,.31a34.81,34.81,0,0,1-5.78,19.24.09.09,0,0,0,.06.15,44.4,44.4,0,0,0,6.45.68c3.46.08,7.11-.37,9.27-2.22,5-4.27,6-11.76,6-20.32C103,35.35,94.55,35,91.84,35Z" />
    <path d="M52,74,25.4,65.13a.1.1,0,0,0-.1.17L51.93,91.93a.1.1,0,0,0,.14,0L78.7,65.3a.1.1,0,0,0-.1-.17L52,74A.06.06,0,0,1,52,74Z" />
    <path d="M75.68,46.9,82,45a.09.09,0,0,0,.08-.09,29.91,29.91,0,0,0-.87-6.94.11.11,0,0,0-.09-.08l-6.43-.58a.1.1,0,0,1-.06-.18l4.78-4.18a.13.13,0,0,0,0-.12,30.19,30.19,0,0,0-3.65-6.07.09.09,0,0,0-.11,0l-5.91,2a.1.1,0,0,1-.12-.14L72.19,23a.11.11,0,0,0,0-.12,29.86,29.86,0,0,0-5.84-4.13.09.09,0,0,0-.11,0l-4.47,4.13a.1.1,0,0,1-.17-.07l.09-6a.1.1,0,0,0-.07-.1,30.54,30.54,0,0,0-7-1.47.1.1,0,0,0-.1.07l-2.38,5.54a.1.1,0,0,1-.18,0l-2.37-5.54a.11.11,0,0,0-.11-.06,30,30,0,0,0-7,1.48.12.12,0,0,0-.07.1l.08,6.05a.09.09,0,0,1-.16.07L37.8,18.76a.11.11,0,0,0-.12,0,29.75,29.75,0,0,0-5.83,4.13.11.11,0,0,0,0,.12l2.59,5.6a.11.11,0,0,1-.13.14l-5.9-2a.11.11,0,0,0-.12,0,30.23,30.23,0,0,0-3.62,6.08.11.11,0,0,0,0,.12l4.79,4.19a.1.1,0,0,1-.06.17L23,37.91a.1.1,0,0,0-.09.07A29.9,29.9,0,0,0,22,44.92a.1.1,0,0,0,.07.1L28.4,47a.1.1,0,0,1,0,.18l-5.84,3.26a.16.16,0,0,0,0,.11,30.17,30.17,0,0,0,2.1,6.76c.32.71.67,1.4,1,2.08a.1.1,0,0,0,.06,0L52,68.16H52l26.34-8.78a.1.1,0,0,0,.06-.05,30.48,30.48,0,0,0,3.11-8.88.1.1,0,0,0-.05-.11l-5.83-3.26A.1.1,0,0,1,75.68,46.9Z" />
  </svg>
);
